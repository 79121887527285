import React, { useState, useEffect } from "react"

import "normalize.css"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VisualAmount from "../components/VisualAmount/VisualAmount"
import AmountInput from "../components/AmountInput/AmountInput"

const IndexPage = () => {
  const [salary, setSalary] = useState(50000)
  const [fee, setFee] = useState(800)
  const [vac, setVac] = useState(0)
  const [soc, setSoc] = useState(0)
  const [buffert, setBuffert] = useState(0)

  const updateSalary = (inp) => {
    setSalary(inp)
    console.log(calculate(fee, salary))
  }

  const updateFee = (inp) => {
    setFee(inp)
    console.log(calculate(fee, salary))
  }

  const calculate = (inpFee, inpSalary) => {
    const doesntExist = !inpFee || !inpSalary
    const isNegative = Number(inpFee) <= 0 || Number(inpSalary) <= 0;
    if(doesntExist || isNegative) {
      setVac(0)
      setSoc(0)
      setBuffert(0)
    } else {
      const arbetsgivarAvgift = 0.3142
      const semester = 0.12
      const hours = 168
      const rev = 0.7 * hours * inpFee
      const vaccation = semester * inpSalary
      const arbetsgivar = arbetsgivarAvgift * inpSalary + (semester*inpSalary*arbetsgivarAvgift)
      const buffert = rev - salary - arbetsgivar - vac
      
      setVac(Math.floor(vaccation))
      setSoc(Math.floor(arbetsgivar))
      setBuffert(Math.floor(buffert))
    }
  }

  useEffect(() => {
    calculate(fee, salary)
  });

  return (
    <Layout>
      <Seo title="Lönesnurra" />
      <section className="snurra">
        <h1 className="main-heading" >
          Lönesnurra<span className="text-yellow">.</span>
        </h1>
        <p>(testa själv i de <span className="text-yellow">gula</span> fälten)</p>
        <AmountInput 
          change={updateSalary}
          heading="Önskad lön (mån)"
          unit="kr"
          amount={salary} />
        <AmountInput 
          change={updateFee}
          heading="Arvode (h)"
          unit="kr"
          amount={fee} />
        <div className="output">
          <VisualAmount 
            heading="Buffert (mån)"
            unit="kr"
            amount={buffert} />
          <VisualAmount 
            heading="Sociala avgifter (mån)"
            unit="kr"
            amount={soc} />
          <VisualAmount 
            heading="Semesterers. (mån)"
            unit="kr"
            amount={vac} />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage